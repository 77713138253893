.imageContainer {
  @apply w-full mb-0.4 text-center;

  @screen sm {
    @apply w-auto text-justify;
  }

  &.left {
    @apply float-left;

    @screen sm {
      @apply mr-6;
    }
  }

  &.right {
    @apply float-right;

    @screen sm {
      @apply ml-6;
    }
  }
}

.textContainer {
  overflow: unset;

  & *:first-child {
    @apply mt-0;
  }
}
