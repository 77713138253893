.accordionContent {
  @apply
    h-auto
    overflow-hidden;

  max-height: 9999px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);

  p {
    @apply py-1.5;
  }
}

.accordionContent.collapsed {
  @apply
    max-h-0;

  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.arrowRight {
  min-width: 0.5rem;
  transition: transform 0.5s;
}

.arrowDawn {
  @apply
    transform
    rotate-180;
}
