.stepContainer {
  counter-reset: ordered-list;
}

.stepContent::before {
  content: counter(ordered-list);
  counter-increment: ordered-list;
  font-size: 0.9285rem;
  font-weight: 600;
}

@media only screen and (min-width: 640px) {
  .stepContainer {
    max-width: calc(100vw - 2.8571rem);
  }

  .stepContent {
    flex-basis: 18.57rem;
  }
}

@media only screen and (min-width: 1400px) {
  .stepContainer {
    width: 100%;
  }

  .stepContent {
    flex-basis: 22%;
  }
}
