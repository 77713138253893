.slider {
  height: 15px;
  padding: 1px 0;
  box-sizing: border-box;

  @apply rounded-lg appearance-none bg-blue-200 w-full;
}

.slider:focus {
  outline: none;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  height: 36px;
  width: 36px;
  border: 7px solid #637da1;
  border-radius: 18px;
  background: #fff;
  transition: box-shadow 0.5s;
}

.slider::-moz-range-thumb {
  height: 25px;
  width: 25px;
  border: 7px solid #637da1;
  border-radius: 20px;
  background: #fff;
  transition: box-shadow 0.5s;
}

.slider::-ms-thumb {
  height: 36px;
  width: 36px;
  border: 7px solid #637da1;
  border-radius: 18px;
  background: #fff;
  transition: box-shadow 0.5s;
}
