.richText {
  overflow-y: auto;

  > ul {
    ul {
      @apply list-disc pl-10;
    }

    @apply list-disc pl-10;
  }

  h5 {
    @apply text-blue-700 font-normal text-base mt-8 mb-2 underline antialiased leading-6;
  }

  ol {
    @apply list-decimal pl-10;
  }

  blockquote {
    p {
      @apply text-blue-560 text-center text-1.5xl leading-9 md:leading-10;
    }
  }

  ol li li {
    list-style-type: lower-alpha;
  }

  ul > li > ul > li {
    @apply list-circle;
  }

  a {
    @apply text-blue-560 font-medium;
  }

  a:hover {
    @apply text-blue-700;
  }

  th,
  td {
    p {
      @apply m-0;
    }
  }

  .tableOfContent {
    ol {
      @apply list-none pl-0;
    }

    p {
      @apply mt-0;
    }
  }

  a[data-type="phone"] {
    white-space: nowrap;
  }
}

.defaultTable {
  th {
    @apply px-3 py-6  text-left min-w-35;
  }

  td {
    @apply p-3;
  }

  tr:nth-of-type(2n) {
    @apply bg-gray-170;
  }

  tbody {
    @apply bg-gray-90;
  }

  table {
    @apply w-full whitespace-pre-line border border-gray-90 my-4;
  }
}

.blueTable {
  th {
    @apply py-5 px-2 text-left text-base text-blue-700;
  }

  td {
    @apply p-3;
  }

  tr:nth-of-type(2n) {
    @apply bg-gray-130;
  }

  thead {
    @apply border-t border-t-4 border-blue-700 bg-gray-130;
  }

  table {
    @apply w-full whitespace-pre-line;
    @apply border border-gray-90;
  }

  tbody {
    @apply bg-white;
  }

  strong {
    @apply text-blue-700;
  }
}

.blackTable {
  th {
    @apply py-5 px-2 text-left font-bold text-sm text-gray-510;
  }

  td {
    @apply p-1;
  }

  tr:nth-of-type(2n) {
    @apply bg-gray-130;
  }

  thead {
    @apply border-t border-t-4 border-blue-700 bg-gray-130;
  }

  table {
    @apply w-full border-l border-r border-b border-gray-90 whitespace-pre-line;
  }

  strong {
    @apply text-gray-510;
  }
}

.tableWithoutHeader {
  th {
    @apply p-3 text-left font-normal;
  }

  tr:nth-of-type(2n) {
    @apply bg-gray-170;
  }

  tbody {
    @apply bg-gray-130;
  }

  table {
    @apply w-full whitespace-pre-line;

    border-width: 4px 1px 1px;
    border-color: theme("colors.blue.700") theme("colors.gray.210") theme("colors.gray.210") theme("colors.gray.210");
  }
}

.plainTable {
  th {
    @apply px-3 py-6  text-left;
  }

  td {
    @apply pb-4  text-left;
  }

  tbody {
    @apply bg-white;
  }

  table {
    @apply w-full whitespace-pre-line border border-gray-90 my-4;
  }
}

.initial {
  display: initial;
}
